<template>
  <div class="login">
    <div class="row justify-content-center" v-if="!reset">
      <div class="col-12 col-md-8">
        <h1 class="page-title">Reset password</h1>
        <b-form autocomplete="off" @submit.stop.prevent="onFormResetSubmit">
          <div class="row">
            <div class="col">
              <b-form-group label="Password" class="custom">
                <b-form-input v-model="form.password" type="password" trim />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b-form-group label="Confirm Password" class="custom">
                <b-form-input v-model="form.password_confirmation" type="password" trim />
              </b-form-group>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-12 col-md-6">
              <div class="form-actions">
                <b-overlay
                  :show="busy"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-btn variant="primary" type="submit" :disabled="busy">
                    Reset password
                  </b-btn>
                </b-overlay>
              </div>
            </div>
          </div>
          <a href="/login">
            Login
          </a>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/mixin/toast"
export default {
  name: 'Reset',
  metaInfo: {
    title: 'Reset',
  },
  mixins: [ Toast ],
  components: {
  },
  data() {
    return {
      reset: false,
      busy: false,
      form: {
        password: '',
        password_confirmation: '',
        reset_password_token: ''
      }
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.form.reset_password_token = this.$route.params.token;
    },
    async onFormResetSubmit() {
      this.busy = true;
      this.$store.dispatch('AuthModule/resetPassword', this.form).then((result) => {
        if(result.data.status){
          this.showToast("success", "Success", "Password changed successfully");

          setTimeout(() => {
            this.$router.push(`/`);
          }, 2000);
        } else {
          this.busy = false;
          this.showToast('danger', 'Error', result.data.errors[0]);
        }
      }, (error) => {
        this.busy = false;
        error.data.errors[0] && this.showToast('danger', 'Error', error.data.errors[0]);
      })
    },
    onClickCreateAccount() {
      this.$router.push(`/register`);
    }
  }
}
</script>

<style lang="scss">
.login {
  .page-title {
    margin: 6rem 0 3rem;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-size: 29px;
    font-weight: 700;
  }

  .login-cta {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .form-group {
    margin: 0.7rem 0;

    legend {
      text-transform: uppercase;
      color: var(--whole-form-legend-color);;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    .form-control {
      border-color: var(--whole-text);
      background: var(--whole-black-form-bg);;
      font-size: 18px;
      color: var(--whole-text);
    }
  }

  .form-actions {
    margin: 2rem 0;
    text-align: right;
    // display: flex;
    // justify-content: space-between;

    .btn {
      border-radius: 0;
      font-weight: 500;
      font-size: 18px;
      text-transform: uppercase;
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
}
</style>
